<template>
	<div class="container">
		<h1 class="sectionHeader">Activity Source</h1>
		<h3 class="explain">
			Chose one of the following data sources. You can change your choice at a
			later date from user settings.
		</h3>

		<ion-button
			class="providerIcon ion-no-padding"
			@click="connectToStrava"
			fill="outline"
		>
			<img src="assets/btn_strava_connectwith_orange.svg" height="96" />
		</ion-button>
		<ion-button
			class="providerIcon garmin ion-no-padding"
			@click="connectToGarmin"
			fill="outline"
		>
			<img src="assets/Garmin Logo With Delta-white-high-res.png" height="96" />
		</ion-button>
		<ion-button
			class="providerIcon coros"
			@click="connectToCoros"
			fill="outline"
		>
			<img src="assets/coros_logo_white.png" height="88" />
		</ion-button>
	</div>
</template>

<script>
	import { IonButton } from "@ionic/vue";
	import { corosAuth, stravaAuth, garminAuth } from "@/services/api.js";
	//import {Browser} from "@capacitor/browser"

	export default {
		name: "activity-provider-picker",
		components: {
			IonButton,
		},
		mounted() {
			let customer = this.$route.query.customer;
			if (customer) {
				this.customer = customer;
			}
		},
		data() {
			return {
				customer: null,
			};
		},
		setup() {},

		methods: {
			isWeb() {
				if (
					process.env.NODE_ENV !== "production" &&
					process.env.NODE_ENV !== "test" &&
					typeof console !== "undefined"
				) {
					return true;
				} else {
					return false;
				}
			},
			async connectToStrava() {
				let protocol;
				if (this.isWeb()) {
					protocol = "http://";
				} else {
					protocol = "myapp://";
				}
				let url = await stravaAuth(protocol);
				console.debug(url);

				await window.location.replace(url);
			},

			async connectToGarmin() {
				let protocol;
				if (this.isWeb()) {
					protocol = "http://";
				} else {
					protocol = "myapp://";
				}
				let url = await garminAuth(protocol);
				let i = 0;

				while (url == undefined && i < 10) {
					//console.debug(`URL: ${url}`)
					url = await garminAuth(protocol);
					i++;
				}

				console.debug(url);

				await window.location.replace(url);
			},

			async connectToCoros() {
				let protocol;
				if (this.isWeb()) {
					protocol = "http://";
				} else {
					protocol = "myapp://";
				}
				let url = await corosAuth(protocol);
				let i = 0;

				while (url == undefined && i < 10) {
					//console.debug(`URL: ${url}`)
					url = await corosAuth(protocol);
					i++;
				}

				console.debug(url);

				await window.location.replace(url);
			},
		},
	};
</script>

<style scoped>
	.sectionHeader {
		color: white;
		font-size: 30px;
		font-weight: bold;
		top: 0px;
	}
	.explain {
		font-size: 16px;
		color: #ffffff;
	}
	.container {
		padding: 15px;
		padding-top: 0px;
	}
	.providerIcon {
		margin: auto;
		height: 96px;
		width: fit-content;
		display: block;
		--border-color: transparent;
		margin-bottom: 20px;
	}

	.garmin {
		--border-color: var(--ion-color-step-200);
		--border-width: 2px;
	}

	.coros {
		--border-color: var(--ion-color-step-200);
		--border-width: 2px;
		--padding-top: 4px;
		--padding-bottom: 4px
	}
</style>
