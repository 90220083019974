<template>
	<base-layout>
		<activity-provider />
		<div v-if="authDone">
			<div style="text-align: center; color: #fff; font-size: 1.15rem">
				Successfully connected to your provider.
			</div>
			<ion-button fill="clear" @click="nextpage">Next</ion-button>
		</div>
	</base-layout>
</template>

<script>
	import ActivityProvider from "../Userdetails/ActivityProvider.vue";

	export default {
		components: { ActivityProvider },
		data() {
			return {
				authDone: false,
			};
		},
		methods: {
			backend() {
				if (window.location.hostname != "join.runxact.com")
					return "http://" + window.location.hostname + ":4962";
				else return "https://3v9jxh7o04.execute-api.eu-west-2.amazonaws.com";
			},

			nextpage() {
				let source = this.$route.query.source;
				let prevCustomer = this.$route.query.customer;
				let sourceid = this.$route.query.sourceid;
				this.$router.push(
					"/signin/signup/details5?existingcustomer=" +
						prevCustomer +
						"&source=" +
						source +
						"&sourceid=" +
						sourceid
				);
			},
		},

		async mounted() {
			let authDone = this.$route.query.authDone == "true" ? true : false;
			this.authDone = authDone;
			if (authDone) {
				this.nextpage();
				return;
			}
		},
	};
</script>

<style scoped>
	ion-button {
		color: var(--ion-color-primary);
		width: 100%;
		border: 0;
		box-shadow: 0;
	}
</style>
